<template>
  <div class="flex w-80 xs:w-96 max-w-full p-4 rounded-lg bg-white border border-gray-300 shadow-md">

    <div class="mr-4" :class="typeClasses">

      <!--      Success icon-->
      <svg v-if="type === 'success'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
        fill="currentColor">
        <path fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clip-rule="evenodd"/>
      </svg>

      <!--      Warning icon-->
      <svg v-if="type === 'warning'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
        fill="currentColor">
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
          clip-rule="evenodd"/>
      </svg>

      <!--      Danger icon-->
      <svg v-if="type === 'error'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
        fill="currentColor">
        <path fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
          clip-rule="evenodd"/>
      </svg>

    </div>

    <div class="grow">
      <div class="font-bold">
        {{ title }}
      </div>
      <div class="text-gray-500">
        {{ message }}
      </div>
    </div>

    <div class="mr-2">
      <button @click="notificationStore.deleteNotification(props.id)" class="text-gray-500">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
          stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>
    </div>

  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useNotificationStore } from '@/store/notification';

const notificationStore = useNotificationStore();

const props = defineProps({
  id: {
    type: String,
  },
  type: {
    type: String,
    default: 'success',
    validator(value) {
      return ['success', 'warning', 'error'].includes(
        value.toLowerCase(),
      );
    },
  },
  title: {
    type: String,
    default: '',
  },
  message: {
    type: String,
    default: '',
  },
});

const typeClasses = computed(() => {
  return {
    'text-green-500': props.type === 'success',
    'text-orange-500': props.type === 'warning',
    'text-red-500': props.type === 'error',
  };
});
</script>

<style scoped>

</style>
