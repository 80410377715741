<template>
  <div class="relative min-h-screen p-4 bg-sky-50">
    <router-view />
  </div>
</template>

<script setup></script>

<style scoped>

</style>
