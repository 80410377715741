<template>
  <component :is="layout">
    <router-view/>
  </component>
  <app-notification-list/>
</template>

<script setup>
import MainLayout from '@/layouts/MainLayout.vue';
import EmptyLayout from '@/layouts/EmptyLayout.vue';
import AppNotificationList from '@/components/notifications/AppNotificationList';

import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const layout = computed(() =>
  route.meta.layout === 'main' ? MainLayout : EmptyLayout,
);
</script>

<style scoped></style>
