<template>
  <div class="fixed top-20 right-4 grid gap-4 sm:right-6">

    <transition-group
      move-class="transition-all duration-500 ease-in-out"
      enter-active-class="transition-all duration-500 ease-in-out"
      leave-active-class="transition-all duration-500 ease-in-out"
      enter-from-class="opacity-0 translate-x-5"
      leave-to-class="opacity-0 translate-x-5"
    >

      <app-notification-message
        v-for="notification in notificationList"
        :id="notification.id"
        :key="notification.id"
        :type="notification.type"
        :title="notification.title"
        :message="notification.message"/>

    </transition-group>

  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useNotificationStore } from '@/store/notification';
import AppNotificationMessage from '@/components/notifications/AppNotificationMessage';

const notificationStore = useNotificationStore();

const notificationList = computed(() => notificationStore.notificationList);
</script>

<style scoped>

</style>
