<template>
  <div class="bg-white shadow">
    <div class="app-container">
      <div class="py-6 lg:border-t lg:border-gray-200">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>

</style>
