import { defineStore } from 'pinia';
import { deleteData, editData, getData, postData } from '@/use/api';
import router from '@/router';

export const useUserProfileStore = defineStore('userProfile', {
  state: () => ({
    id: null,
    name: '',
    email: '',
    phone: '',
    avatar: '',
    role: '',
  }),

  getters: {

    userAvatar: (state) =>
      state.avatar !== '' ?
        `${ process.env.VUE_APP_BASE_URL }${ state.avatar }` :
        `/img/no-avatar.png`,
  },

  actions: {

    async getCurrentUserData() {
      const response = await getData('/users');
      if (!response.error) {
        this.id = response.id;
        this.name = response.name;
        this.email = response.email;
        this.phone = response.phoneNumber;
        this.role = response.role;
        this.avatar = response.image ? response.image.link : '';
      }
    },

    async editUserData(newUserData) {
      const response = await editData(`/users`, newUserData);
      if (!response.error) {
        this.name = newUserData.name;
        this.email = newUserData.email;
        this.phone = newUserData.phone;
      }
      return response;
    },

    async changeUserPassword(oldPassword, newPassword) {
      return postData('/auth/change-pwd', {
        oldPassword: oldPassword,
        newPassword: newPassword,
      });
    },

    async logOut() {
      const response = await postData('auth/logout');
      if (!response.error) {
        await router.push({name: 'LogIn'});
        this.id = null;
        this.name = '';
        this.email = '';
        this.phone = '';
        this.role = '';
        this.avatar = '';
        localStorage.clear();
      }
    },

    async deleteUser() {
      return await deleteData(`/users`);
    },

  },
});
