import axios from 'axios';
import router from '@/router';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

api.interceptors.request.use((config) => {
  if (localStorage.getItem('token')) {
    config.headers = {
      Authorization: `Bearer ${ localStorage.getItem('token') }`,
    };
  }

  return config;
}, error => Promise.reject(error));

api.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    if (error.response?.status === 401) router.push('/log-in');
    return Promise.reject(error);
  },
);

const getData = async (url) => {
  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const postData = async (url, payload) => {
  try {
    const response = await api.post(url, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteData = async (url, payload) => {
  try {
    const response = await api.delete(url, {data: payload});
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const editData = async (url, payload) => {
  try {
    const response = await api.put(url, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export { getData, postData, deleteData, editData, api };
