import { createRouter, createWebHistory } from 'vue-router';
import MainPage from '@/views/MainPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      layout: 'main',
      auth: true,
      menuItem: 'Home'
    },
    component: MainPage,
  },
  {
    path: '/log-in',
    name: 'LogIn',
    meta: {
      layout: 'empty',
    },
    component: () => import('@/views/LogInPage.vue'),
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    meta: {
      layout: 'empty',
    },
    component: () => import('@/views/SignUpPage.vue'),
  },
  {
    path: '/users',
    name: 'Users',
    meta: {
      layout: 'main',
      auth: true,
      menuItem: 'Users'
    },
    component: () => import('@/views/users/UserListPage.vue'),
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    meta: {
      layout: 'main',
      auth: true,
    },
    component: () => import('@/views/UserProfilePage'),
  },
  {
    path: '/users/:id',
    name: 'User',
    meta: {
      layout: 'main',
      auth: true,
      menuItem: 'Users'
    },
    component: () => import('@/views/users/UserPage.vue'),
    children: [
      {
        path: '',
        name: 'UserData',
        component: () => import('@/components/user/AppUserData'),
      },
      {
        path: 'pets',
        name: 'UserPets',
        component: () => import('@/components/pets/AppUserPets'),
      },
      {
        path: 'addresses',
        name: 'UserAddresses',
        component: () => import('@/components/addresses/AppUserAddresses'),
      },
      {
        path: 'orders',
        name: 'UserOrders',
        component: () => import('@/views/users/UserOrderListPage'),
      },
    ],
  },
  {
    path: '/users/create',
    name: 'CreateUser',
    meta: {
      layout: 'main',
      auth: true,
      menuItem: 'Users'
    },
    component: () => import('@/views/CreateUserPage.vue'),
  },
  {
    path: '/pets',
    name: 'Pets',
    meta: {
      layout: 'main',
      auth: true,
      menuItem: 'Pets'
    },
    component: () => import('@/views/PetsPage.vue'),
  },
  {
    path: '/pets/:id',
    name: 'Pet',
    meta: {
      layout: 'main',
      auth: true,
      menuItem: 'Pets'
    },
    component: () => import('@/views/PetPage.vue'),
  },
  {
    path: '/pets/create',
    name: 'CreatePet',
    meta: {
      layout: 'main',
      auth: true,
      menuItem: 'Pets'
    },
    component: () => import('@/views/CreatePetPage.vue'),
  },
  {
    path: '/addresses',
    name: 'Addresses',
    meta: {
      layout: 'main',
      auth: true,
      menuItem: 'Addresses'
    },
    component: () => import('@/views/AddressesPage.vue'),
  },
  {
    path: '/orders',
    name: 'Orders',
    meta: {
      layout: 'main',
      auth: true,
      menuItem: 'Orders'
    },
    component: () => import('@/views/orders/OrderListPage'),
  },
  {
    path: '/orders/:id',
    name: 'Order',
    meta: {
      layout: 'main',
      auth: true,
      menuItem: 'Orders'
    },
    component: () => import('@/views/orders/OrderDetailsPage'),
  },
  {
    path: '/orders/:id/edit',
    name: 'OrderEdit',
    meta: {
      layout: 'main',
      auth: true,
      menuItem: 'Orders'
    },
    component: () => import('@/views/orders/OrderEditPage'),
  },
  {
    path: '/comments',
    name: 'Comments',
    meta: {
      layout: 'main',
      auth: true,
      menuItem: 'Comments'
    },
    component: () => import('@/views/CommentsPage'),
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    meta: {
      layout: 'main',
      auth: true,
      menuItem: 'Subscriptions'
    },
    component: () => import('@/views/SubscriptionsPage'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
});

router.beforeEach((to, from, next) => {

  const requireAuth = to.meta.auth;

  const token = localStorage.getItem('token');

  if (requireAuth && !token) {
    next({name: 'LogIn'});
  } else {
    next();
  }

});

export default router;
