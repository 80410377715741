<template>
  <div class="avatar">
    <img :src="userAvatar" alt="user avatar" class="avatar__img">
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  avatarUrl: {
    type: String,
    required: true,
  },
});

const userAvatar = computed(() => props.avatarUrl ? process.env.VUE_APP_BASE_URL + props.avatarUrl : '/img/no-avatar.png')
</script>

<style scoped>
.avatar {
  @apply relative w-full h-full rounded-full overflow-hidden
}

.avatar__img {
  @apply absolute top-0 left-0 w-full h-full object-cover
}
</style>
